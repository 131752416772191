.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  
}

.leftColumn {
  display:flex;
  justify-content: space-evenly;
}

@media screen and (min-width: 1224px) {
  .imageContainer h1 {
    font-size: 1.5em;
  }
  .leftColumn {
    padding-top: 25px;
    flex: 0 0 auto;
    order: 1;
    flex-direction: column;
    gap: 2%;
    justify-content: normal;
    margin-left: 50px;
  }
  
  .mainContent {
    flex: 2 1 auto;
    order: 2;
    margin-bottom: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x:hidden
  }

  .social-container {
    max-width: 150px;
  }

  .container {
    display: flex;
    height: calc(80vh - 24px);
    overflow-y: hidden;
    gap: 1em;
    max-width:1500px;
    margin: 0 auto;
  }

  .navLinks a {
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none;
    color: #b9ba98;
    font-weight: bold;
  }
  
  .navLinks a:visited {
    color: #b9ba98;
  }

  .App-header {
    border-bottom: solid #deaf41 10px;
  }
}
.App-header {
  background-color: #3e5c98;
  height: 10vh;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  gap: 5%;
  border-bottom: solid #deaf41 4px;
}

.App-footer {
  background-color: #3e5c98;
  height: 10vh;
  display: flex;
  color:white;
  margin-top: 20px;
}

.container {
  min-height: calc(80vh - 24px);
}

.navLinks a {
  text-decoration: none;
  color:white
}

.navLinks a:visited {
  color: white;
}

.imageContainer {
  height: 100%;
  flex: 0 0 auto;
  text-align: left;
  display: flex;
}
.imageContainer h1 {
  margin-top: auto;
  margin-bottom: auto;
}

.authButton {
  flex: 0 0 10%;
  margin-top: auto;
  margin-bottom: auto;
}

.App-link {
  color: #61dafb;
}

.navLinks {
  height:100%;
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-container {
  text-align: center;
  padding: 10px;
  border: solid lightgray 1px;
  border-radius: 5%;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.youtube {
  color: #eb3223;
}

a.instagram {
  color: black;
}

.mainContent {
  padding: 0px 10px;
  margin-bottom: 10px;
}

.mainContent p {
  text-align: left;
}